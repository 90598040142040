import { React, useState, useEffect } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import axios from "axios";
import dynamic_urls from "../../../../env";
import { FaPen, FaTools, FaUserCircle } from "react-icons/fa";
import "../../../../CSS/Add.css"

const UserViewFunc = () => {
    const [user, setUser] = useState([
        {
            "id": null,
            "first_name": null,
            "last_name": null,
            "email": null,
            "username": null,
            "is_superuser": null
        }
    ]);
    const [update, setUpdate] = useState(false)
    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(
                    dynamic_urls.SERVER_URL + dynamic_urls.users + "?search=" + JSON.parse(localStorage.getItem("user")).username,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                setUser(response.data)
            } catch (e) {
                console.log("not auth");
            }
        })();
    }, [update]);
    const handleSubmit = (e) => {
        e.preventDefault();
        let JSONdata = {
            username: e.target.username.value,
            email: e.target.email.value,
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
        };
        axios.put(dynamic_urls.SERVER_URL + dynamic_urls.users + user[0].id + "/", JSONdata, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }).then(
            (result) => {
                console.log(result)
                alert("Sucessfully updated User Detail!");
                setUpdate(false)
            },
            (error) => {
                alert("Failed to Update Spare part");
            }
        );
    };

    return (
        <div className="body-mrgn">
            <h2>
                <FaUserCircle className="fa-style" />User Details
                <FaPen className="fa-style-black" onClick={()=>setUpdate(true)} />
            </h2>
            <Form onSubmit={handleSubmit} className="white-bg">
                <Row>
                    <Col sm={6}>
                        <Form.Group controlId="First Name">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="first_name"
                                defaultValue={user[0].first_name}
                                disabled={!update}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                defaultValue={user[0].username}
                                disabled={!update}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={6}>
                        <Form.Group controlId="second_name">
                            <Form.Label>Second name</Form.Label>
                            <Form.Control
                                type="text"
                                name="last_name"
                                defaultValue={user[0].last_name}
                                disabled={!update}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                name="email"
                                defaultValue={user[0].email}
                                disabled={!update}
                                required
                            />
                        </Form.Group>
                    </Col>
                    {update && 
                        <Col sm={12}>
                            <Form.Group>
                                <div className="pt-4 submit text-end">
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </div>
                            </Form.Group>
                        </Col>
                    }
                </Row>
            </Form>
        </div>
    );
};

export default UserViewFunc;
