import React, { useState, useEffect } from "react";
import "../../CSS/Header.css";
import { Link } from 'react-router-dom'
import SidebarFunc from "./Sidebar.js";
import { Col, Row, Form, Button } from "react-bootstrap";
import axios from "axios";
import dynamic_urls from "../../env";
import { FaSignOutAlt, FaUserCircle } from "react-icons/fa";

const HeaderFunc = (props) => {
	const [isAuth, setIsAuth] = useState(props.isAuth);
	// useEffect(() => {
	// 	if (localStorage.getItem("access_token") === null) {
	// 		window.location.href = "/";
	// 	}
	// }, []);
	// useEffect(() => {
	// 	if (localStorage.getItem("access_token") !== null) {
	// 		setIsAuth(true);
	// 	}
	// }, [isAuth]);
	const logout = () => {
		axios
			.post(
				dynamic_urls.SERVER_URL + dynamic_urls.logout,
				{
					refresh_token: localStorage.getItem("refresh_token"),
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				},
				{ withCredentials: true }
			)
			.then(
				(result) => {
					localStorage.clear();
					axios.defaults.headers.common["Authorization"] = null;
					window.location.href = "/";
				},
				(error) => {
					alert("Failed to logout");
				}
			);
	};
	return (

		<div className="fixTop">

			<nav className="navbar header-bg">
				<a className="navbar-brand" href="/">
					<h2>Incident Management System</h2>
				</a>
				{/* <nav className="mx-auto">
					<a className="nav-link" id="home" href="/">
						Home
					</a>
				</nav> */}

				{props.isAuth &&
					<nav className="nav nav-pills" >
						<Link to="/user/view" className="link">
							<FaUserCircle className="nav-icon2" style={{marginTop:"10px", fontSize:"20px"}}/>
						</Link>
						<a
							className="nav-link text-dark"
							role="button"
							// href="/logout"
							onClick={logout}
						>
							<FaSignOutAlt className="nav-icon2" />Logout
						</a>
					</nav>
				}
			</nav>
			{/* <div className="container-fluid" id="container">
				<div className="row">
					<div className="col-md-2" id="sidebar">
						<SidebarFunc />
					</div>
				</div>
			</div> */}
			{/* <SidebarFunc /> */}
		</div>
	);
};

export default HeaderFunc;
