import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { Col, Row, Form, Button, ButtonToolbar } from "react-bootstrap";
import { RiDeleteBin5Line } from "react-icons/ri";
import "../../../../CSS/View.css";
import IncidentUpdateFunc from "../Update/IncidentUpdate";
import IncidentUpdateHistoryFunc from "../Update/IncidentUpdateHistory";
import axios from "axios";
import { useLongPress } from "use-long-press";
import dynamic_urls from "../../../../env.js"
import { Link } from 'react-router-dom'

const IncidentViewFunc = () => {
	const [incidents, setIncidents] = useState([]);
	const [incidentsErr, setIncidentsErr] = useState(0);
	const [searchedIncidents, setSearchedIncidents] = useState([]);
	const [paginatedIncident, setPaginatedIncident] = useState([])
	// const [employees, setEmployees] = useState([]);
	const [editModalShow, setEditModalShow] = useState(false);
	const [editHistoryModalShow, setEditHistoryModalShow] = useState(false);
	const [editHistory, setEditHistory] = useState([]);
	const [editIncident, setEditIncident] = useState({});
	const [isUpdated, setIsUpdated] = useState(0);
	const [isDeleted, setIsDeleted] = useState(0);
	const [isSuperuser, setIsSuperuser] = useState(false);
	const [showEntries, setShowEntries] = useState(2);
	const [searchTerm, setSearchTerm] = useState("");
	const [dataSize, setDataSize] = useState(0);
	const [pageCount, setPageCount] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [pagination, setPagination] = useState(["<Previous", 1, "Next>"]);
	useEffect(() => {
		if (JSON.parse(localStorage.getItem("user")).is_superuser) {
			setIsSuperuser(true);
		}
	}, []);
	
	useEffect(() => {
		(async () => {
			try {
				const response = await axios.get(
					dynamic_urls.SERVER_URL + dynamic_urls.filteredIncidents + JSON.parse(localStorage.getItem("user")).email + "/",
					{
						headers: {
							"Content-Type": "application/json",
						},
					}
				);
				if (response.name === "AxiosError")
					setIncidentsErr(incidentsErr + 1);
				else setIncidents(response.data);
			} catch (e) {
				console.log("not auth");
			}
		})();
	}, [isUpdated, isDeleted, incidentsErr]);

	useEffect(() => {
		// console.log(dynamic_urls.SERVER_URL + dynamic_urls.filteredIncidents + JSON.parse(localStorage.getItem("user")).email + "/")
		// console.log(dynamic_urls.SERVER_URL + dynamic_urls.filteredIncidents + JSON.parse(localStorage.getItem("user")).email + "/" + searchTerm + "/")
		if (searchTerm.length) {
			(async () => {
				try {
					const response = await axios.get(
						dynamic_urls.SERVER_URL + dynamic_urls.filteredIncidents + JSON.parse(localStorage.getItem("user")).email + "/" + searchTerm + "/",
						{
							headers: {
								"Content-Type": "application/json",
							},
						}
					);
					setSearchedIncidents(response.data)

				} catch (e) {
					console.log("not auth");
				}
			})();
		}
		else
			setSearchedIncidents(incidents)
	}, [searchTerm, incidents]);



	useEffect(() => {
		if (searchedIncidents && searchedIncidents.length && showEntries) {
			setPageCount(Math.ceil(searchedIncidents.length / showEntries))
		}
	}, [searchedIncidents, showEntries, incidents]);

	useEffect(() => {
		if (searchedIncidents) {
			setPaginatedIncident(
				x => {
					let ob = [...searchedIncidents]
					return ob.slice(
						showEntries * (pageNumber - 1), showEntries * pageNumber
					)
				}
			)
		}
	}, [showEntries, pageNumber, searchedIncidents, incidents, pageCount]);

	useEffect(() => {
		if (pageCount == 1)
			setPagination(["<Previous", 1, "Next>"])
		if (pageCount > 1 && pageCount < 8) {
			let arr = ["<Previous", 1, "Next>"]
			for (let i = 2; i <= pageCount; i++) {
				arr.splice(i, 0, i)
			}
			setPagination(arr)
		}
		if (pageCount > 7 && pageNumber) {
			console.log("pageNumber", pageNumber)
			let arr = ["<Previous", 1, "Next>"]
			if (pageNumber <= 3) {
				for (let i = 2; i <= 5; i++) {
					arr.splice(i, 0, i)
				}
				arr.splice(6, 0, "...")
				arr.splice(7, 0, pageCount)
			}
			else if (pageNumber > pageCount - 3) {
				arr.splice(2, 0, "...")
				for (let i = pageCount; i > pageCount - 5; i--) {
					arr.splice(3, 0, i)
				}
			}
			else {
				arr.splice(2, 0, "...", pageNumber - 1, pageNumber, pageNumber + 1, "...", pageCount)
			}
			setPagination(arr)
		}
	}, [pageCount, pageNumber, showEntries, searchedIncidents, incidents, paginatedIncident]);

	let clickHoldTimer = null;

	const handleMouseDown = (e, stu) => {
		if (!editModalShow) {
			clickHoldTimer = setTimeout(() => {
				e.preventDefault();
				setEditHistoryModalShow(true);
				setEditHistory(stu.editHistory);
				//Action to be performed after holding down mouse
			}, 2000)
		} //Change 1000 to number of milliseconds required for mouse hold
	};

	const handleMouseUp = () => {
		clearTimeout(clickHoldTimer);
	};

	const handleUpdate = (e, stu) => {
		e.preventDefault();
		setEditModalShow(true);
		setEditIncident(stu);
	};

	const handleUpdateState = () => {
		setIsUpdated(isUpdated + 1);
	};

	const handleDeleteState = () => {
		setIsDeleted(isDeleted + 1);
	};

	const handleDelete = (e, incidentId) => {
		if (window.confirm("Are you sure ?")) {
			e.preventDefault();
			handleDeleteState();
			axios
				.delete(dynamic_urls.SERVER_URL + dynamic_urls.incidents + incidentId + "/", {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				})
				.then(
					(result) => {
						alert("Successfully deleted");
					},
					(error) => {
						alert("Failed to Delete");
					}
				);
		}
	};

	const uniqueEmployeeID = (id) => {
		if (id < 10) return `EMP-100${id}`;
		else return `EMP-10${id}`;
	};

	const uniqueCustomerID = (id) => {
		if (id < 10) return `CUST-100${id}`;
		else return `CUST-10${id}`;
	};

	const date = (d) => {
		let arr = d.split("-");
		let newDate = arr[2] + "-" + arr[1] + "-" + arr[0];
		return newDate;
	};

	const time = (t) => {
		let hr = Number(t.split(":")[0])
		if (hr >= 12) {
			if (hr > 12)
				return String(hr - 12) + ":" + t.split(":")[1] + ":" + t.split(":")[2] + " PM"
			else
				return String(hr) + ":" + t.split(":")[1] + ":" + t.split(":")[2] + " PM"
		}
		else {
			if (hr < 1)
				return "12" + ":" + t.split(":")[1] + ":" + t.split(":")[2] + " AM"
			else
				return String(hr) + ":" + t.split(":")[1] + ":" + t.split(":")[2] + " AM"
		}

	}
	let EditModalClose = () => setEditModalShow(false);
	let EditHistoryModalClose = () => setEditHistoryModalShow(false);
	let userEmail = JSON.parse(localStorage.getItem("user")).email;
	// console.log(pageCount)	

	return (
		<div className="body-mrgn" style={{ padding: "45.531px 30px 20px 30px" }}>
			<Row style={{ paddingBottom: "10px" }}>
				<Col sm={10}>
					<h2><FaFileAlt className="fa-style" /> Incident Details</h2>
				</Col>
				<Col sm={2}>
					<Link to="/incidents/add" className="link">
						<div className="submit float-end px-0" style={{ paddingLeft: 0, paddingRight: 0, position: "relative" }}>
							<Button variant="primary" type="submit" className="float-end">
								Add Incident
							</Button>
						</div>
					</Link>
				</Col>
			</Row>
			<Row>
				<Col sm={2}>
					<label htmlFor="showEntry"><p style={{ fontSize: "14px" }}>
						Show entries:
					</p></label>
					<select
						onChange={(e) => {
							setShowEntries(Number(e.target.value));
						}}
						id="showEntry"
						style={{ marginLeft: "5px", height: "20px", fontSize: "14px" }}
					>
						<option value="2">
							2
						</option>
						<option value="3">
							3
						</option>
						<option value="4">
							4
						</option>
						<option value="5">
							5
						</option>
					</select>
				</Col>
				<Col sm={6}></Col>
				<Col sm={4} style={{ paddingLeft: "53px", paddingRight: "0px" }}>
					<label htmlFor="fname"><p style={{ fontSize: "14px" }}>
						Search by Customer:
					</p></label>
					<input
						type="text"
						id="fname"
						style={{ marginLeft: "5px", width: "180px", height: "20px" }}
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}
					/>
				</Col>
			</Row>
			<div className="row side-row" style={{ paddingBottom: "20px" }}>
				{/* <h3 align="center">Incident Details</h3> */}
				{/* <p id="before-table"></p> */}
				<div
					className="tableFixHead"
					style={{ paddingLeft: 0, paddingRight: 0, marginTop: "-30px" }}
				>
					<Table
						striped
						bordered
						hover
						className="react-bootstrap-table"
						id="dataTable"
					>
						<thead>
							<tr>
								<th>ID</th>
								<th>Incident Description</th>
								<th style={{ width: 99 }}>Incident Date</th>
								<th style={{ width: 99 }}>Incident Time</th>
								<th>Customer Name</th>
								<th style={{ width: 97 }}>Customer ID</th>
								<th>Product Name</th>
								<th>Employee Name</th>
								<th>Employee ID</th>
								<th>Severity</th>
								<th>Complexity</th>
								<th>Level</th>
								{/* <th>Total Allotted Time(in hrs)</th> */}
								<th>Incident Remark</th>
								<th>Incident Status</th>
								<th>Estimated completion date</th>
								<th>Estimated completion time</th>
								<th>Amount</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{paginatedIncident &&
								paginatedIncident.map((stu) =>
									<tr
										key={stu.incidentId}
										onMouseDown={(event) =>
											handleMouseDown(event, stu)
										}
										onMouseUp={handleMouseUp}
									>
										<td>{stu.customIncidentId}</td>
										{/* <td><div dangerouslySetInnerHTML={{__html: stu.incidentDescription}}></div></td> */}
										<td>{stu.incidentDescription}</td>
										<td>{date(stu.incidentDate)}</td>
										<td>{time(stu.incidentTime)}</td>

										<td>{stu.customer.name}</td>
										<td>
											{uniqueCustomerID(
												stu.customer.customerId
											)}
										</td>
										<td>{stu.product ? stu.product.name : null}</td>
										<td>{stu.employee ? stu.employee.name : null}</td>
										<td>
											{stu.employee ?
												uniqueEmployeeID(
													stu.employee.employeeId
												) : null}
										</td>
										<td style={{ textAlign: "center" }}>
											{stu.severity ? stu.severity.name : null}
										</td>
										<td style={{ textAlign: "center" }}>
											{stu.complexity ? stu.complexity.name : null}
										</td>
										<td>{stu.level ? stu.level.name : null}</td>
										{/* <td>{stu.level.tat}</td> */}
										<td>{stu.incidentRemark}</td>
										<td>{stu.incidentStatus.name}</td>
										<td>{stu.expectedCompletionDate ? date(stu.expectedCompletionDate) : null}</td>
										<td>{stu.expectedCompletionTime ? time(stu.expectedCompletionTime) : null}</td>
										<td>{stu.amount ? `₹${stu.amount}` : null}</td>
										<td width="110rem">
											{isSuperuser && (
												<Button
													className="mr-2"
													variant="danger"
													onClick={(event) =>
														handleDelete(
															event,
															stu.incidentId
														)
													}
												>
													<RiDeleteBin5Line />
												</Button>
											)}
											<span>&nbsp;&nbsp;</span>
											<Button
												className="mr-2"
												variant="success"
												onClick={(event) =>
													handleUpdate(event, stu)
												}
											>
												<FaEdit />
											</Button>

											<IncidentUpdateFunc
												show={editModalShow}
												incident={editIncident}
												setupdated={handleUpdateState}
												onHide={EditModalClose}
											></IncidentUpdateFunc>
											<IncidentUpdateHistoryFunc
												show={editHistoryModalShow}
												edit={editHistory}
												onHide={EditHistoryModalClose}
											></IncidentUpdateHistoryFunc>
										</td>
									</tr>
								)}
						</tbody>
					</Table>
				</div>
			</div>
			<div style={{ float: "right" }}>
				{pagination.map((stu) => {
					if (stu == "<Previous") {
						return (
							<button
								style={{
									// backgroundColor: pageNumber==pageCount? "grey": "white",
									// color: pageNumber==pageCount? "white": "black",
									border: "2px solid grey",
									borderRightWidth: "0px"
								}}
								disabled={pageNumber == 1 ? true : false}
								onClick={(e) => {
									if (!e.target.disabled)
										setPageNumber(pageNumber - 1)
								}}
							>
								{stu}
							</button>
						)
					}
					else if (stu == "Next>") {
						return (
							<button
								style={{
									// backgroundColor: pageNumber==pageCount? "grey": "white",
									// color: pageNumber==pageCount? "white": "black",
									border: "2px solid grey",
								}}
								disabled={pageNumber == pageCount ? true : false}
								onClick={(e) => {
									if (!e.target.disabled)
										setPageNumber(pageNumber + 1)
								}}
							>
								{stu}
							</button>
						)
					}
					else {
						if (stu == pageNumber) {
							return (
								<button
									style={{
										backgroundColor: "#A9D08E",
										border: "2px solid grey",
										borderRightWidth: "0px"
									}}
								>
									{stu}
								</button>
							)
						}
						else {
							return (
								<button
									style={{
										border: "2px solid grey",
										borderRightWidth: "0px"
									}}
									onClick={(e) => {
										setPageNumber(stu);
									}}
								>
									{stu}
								</button>
							)
						}
					}
				})}
			</div>
		</div>
	);
};
export default IncidentViewFunc;
