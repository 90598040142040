// const SERVER_URL = "http://localhost:8000/";
const SERVER_URL = "https://incident-back.spaatech.net/";

const dynamic_urls = {
    SERVER_URL: SERVER_URL,
    logout: "api/logout/",
    superuser: "api/superuser/",
    users: "api/users/",
    incidentStatuses: "api/incidentStatuses/",
    levels: "api/levels/",
    spareParts: "api/spareParts/",
    degrees: "api/degrees/",
    skills: "api/skills/",
    services: "api/services/",
    products: "api/products/",
    employees: "api/employees/",
    customers: "api/customers/",
    incidents: "api/incidents/",
    filteredIncidents: "api/filteredIncident/",
    searchedIncidents: "api/searchedIncident/",
    registeredVsResolved: "api/registeredVsResolved/",
}

export default dynamic_urls